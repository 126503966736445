.arf-default-grid {
  .arf-faq, .arf-faq-container {
    .text-section-small-title {
      border-left: none;
      margin-left: 0;
      padding-left: 0;
      line-height: 1.5em;

      @media (max-width: $breakpoint-medium-max) {
        text-align: left;
      }
    }
  }
}

.arf-faq {
  nav {
    position: sticky;
    top: 150px;

    margin-top: 75px;

    @media (max-width: $breakpoint-medium-max) {
      margin-top: 50px;
    }

    @media (max-width: $breakpoint-small-max) {
      margin-top: 25px;
    }

    ul {
      list-style-type: none;
      margin: 20px 0 0 0;
      padding: 0;
    }

    .uk-active {
      a {
        color: var(--dark-blue);
        font-weight: 600;
      }
    }

    a {
      border: none;

      color: var(--gray-500);
      font-weight: 300;

      &:hover {
        color: var(--dark-blue);
        font-weight: 600;
      }
    }
  }
}

.arf-faq-container {
  @media (min-width: $breakpoint-small) and (max-width: $breakpoint-medium-max) {
    grid-column: 2 / span 2;
    padding-left: 0;
    padding-right: 0;
  }

  .anchor {
    @media (min-width: $breakpoint-small) {
      &:not(:first-child) {
        .text-section-small-title {
          display: none;
        }
      }
    }

    @media (max-width: $breakpoint-xsmall-max) {
      padding-top: 30px;
      border-top: 1px solid var(--dark-blue);

      .text-section-small-title {
        margin-bottom: 0;
      }
    }
  }

  details {
    width: 100%;
    margin-top: 15px;
    margin-left: -15px;

    border-bottom: 1px solid var(--gray-900);

    @media (max-width: $breakpoint-xsmall-max) {
      margin-left: 0;
    }

    summary {
      display: flex;

      padding: 1rem 15px;
      color: var(--dark-blue);
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 1.2em;

      list-style: none;

      transition-duration: var(--transition-duration-default);

      @media (max-width: $breakpoint-xsmall-max) {
        padding: 1rem 0;
      }

      &::-webkit-details-marker {
        display: none;
      }

      &:hover {
        background-color: var(--gray-900);

        cursor: pointer;
      }

      &:after {
        content: "+";
        display: inline-block;
        margin-left: auto;
        padding-left: 20px;

        color: var(--hightlight-color);
        font-size: 2rem;
      }
    }

    .answer {
      margin-top: -10px;
      padding-left: 15px;
      opacity: 0;

      color: var(--dark-blue);

      transition-duration: var(--transition-duration-default);


      @media (max-width: $breakpoint-xsmall-max) {
        padding-left: 0;
      }
    }

    &[open] {
      border-bottom: 1px solid var(--gray-500);

      summary {
        &:after {
          content: "-";
        }
      }

      .answer {
        margin-top: 0;
        opacity: 1;

        transition-duration: var(--transition-duration-default);
      }
    }
  }

  details + .anchor {
    margin-top: 100px;

    @media (max-width: $breakpoint-xsmall-max) {
      margin-top: 50px;
    }
  }
}
