.arf-history {
  h2 {
    margin-top: 10px;
    font-weight: 300;
  }

  &__block {
    &:nth-child(2) { // First one (1 is the grid lines div)
      .inner {
        padding: 175px 0 45px;

        @media (max-width: $breakpoint-small-max) {
          margin-top: -75px;
          padding-top: 0;
        }
      }

      .trail {
        @media (min-width: $breakpoint-medium) {
          display: none;
        }
      }
    }

    &.has_img {
      .inner {
        grid-column: 2 / -1 !important;
        display: grid;
        grid-template-columns: 50% 50%;
      }
    }

    &.has_img:nth-child(even) + & {
      .trail {
        margin-top: -100px;
      }
    }

    &.has_img:nth-child(even) {
      .trail {
        @media (min-width: $breakpoint-medium) {
          grid-area: inherit;
        }
      }
    }

    &:nth-child(even) {
      .inner {
        grid-column: 2 / -1;

        @media (max-width: $breakpoint-medium-max) {
          grid-column: 1 / -1 !important;
        }

        @media (max-width: $breakpoint-small-max) {
          grid-template-columns: 100%;
          padding-top: 0;
        }
      }

      &.wrapper {
        grid-column: 1/ -1;

        //background-image: url("/static/arf-images/history-01.png");
        background-position: left -100px;
        background-size: contain;
        background-repeat: no-repeat;

        @media (max-width: $breakpoint-small-max) {
          background-position: center center;
          background-size: cover;
        }
      }

      .trail {
        grid-row: 2;
        margin-top: 30px;

        width: 100%;
        height: 300px;

        background: url("/static/arf-images/history-line-03.svg") center center / contain no-repeat;

        @media (min-width: $breakpoint-medium) {
          margin-top: 20px;
          background-size: 100% 100%;
          height: 400px;
        }
      }

      .content {
        padding: 0 20px;

        @media (max-width: $breakpoint-medium-max) {
          margin-left: 20px;
        }

        @media (max-width: $breakpoint-small-max) {
          grid-row: 3;
          margin-left: 0;

          text-align: center;
        }

        .text-section-small-title {
          @media (max-width: $breakpoint-small-max) {
            border: none;
          }
        }
      }
    }

    &:nth-child(odd) {
      .inner {
        grid-column: 2 / span 4;

        @media (max-width: $breakpoint-medium-max) {
          grid-column: 1 / -1;
        }
      }

      &.wrapper {
        grid-column: 1/ -1;

        //background: url("/static/arf-images/history-02.png") left top / contain no-repeat;
        background-position: left top;
        background-size: contain;
        background-repeat: no-repeat;

        @media (max-width: $breakpoint-xsmall-max) {
          background-image: url("/static/arf-images/history-04.png");
        }
      }

      .trail {
        grid-column: 1 / -1;

        height: 400px;
        width: 100%;
        margin-top: 20px;
        margin-right: -5vw;

        background: url("/static/arf-images/history-line-01.svg") center top / contain no-repeat;

        @media (max-width: $breakpoint-medium-max) {
          height: 300px;
        }

        @media (max-width: $breakpoint-small-max) {
          height: 250px;
          margin-top: -50px;

          background-image: url("/static/arf-images/history-line-04.svg");
          background-position: center center;
          background-size: contain;
        }
      }

      .content {
        text-align: center;

        margin-top: 25px;
        padding: 0 20px;

        @media (max-width: $breakpoint-small-max) {
          margin-top: 0;
        }

        h2 {
          padding: 0 50px;
        }

        p {
          margin: 50px 0 0;
          padding: 0 100px;

          @media (max-width: $breakpoint-small-max) {
            padding: 0;
          }
        }
      }
    }

    .image {
      width: 100%;
      text-align: center;
      margin-top: 50px;

      @media (max-width: $breakpoint-small-max) {
        grid-row: 1;
        margin-top: 0;
      }

      img {
        max-width: 80%;

        @media (max-width: $breakpoint-small-max) {
          width: 100%;
          max-width: unset;
          height: 450px;

          object-fit: contain;
          object-position: calc(100% + 100px) top;
        }
      }
    }
  }

  &__block-timeline {
    grid-column: 3 / span 3;

    @media (max-width: $breakpoint-medium-max) {
      grid-column: 1 / -1;
    }

    &__wrapper {
      grid-column: 1/ -1;
      margin-top: 25px;

      background: url("/static/arf-images/history-03.png") center bottom / contain no-repeat;

      @media (max-width: $breakpoint-medium-max) {
        margin: 20px 0 0;
      }

      @media (max-width: $breakpoint-small-max) {
        background-position: center center;
      }
    }

    &__trail {
      grid-column: 2 / span 4;
      height: 350px;
      width: 100%;
      margin-top: 25px;

      background: url("/static/arf-images/history-line-02.svg") left top / contain no-repeat;

      @media (max-width: $breakpoint-medium-max) {
        grid-column: 1 / -1;
        height: 250px;
        background-position: center top;
      }

      @media (max-width: $breakpoint-small-max) {
        grid-column: 1 / -1;
        height: 250px;

        background-image: url("/static/arf-images/history-line-05.svg");
        background-position: 0 top;
      }
    }

    &__content {
      margin-top: -40px;
      padding: 0 20px;

      @media (max-width: $breakpoint-medium-max) {
        margin-top: -20px;
        text-align: center;

        .text-section-small-title {
          border: none;
        }
      }
    }
  }
}

.arf-timeline {
  grid-column: 1 / -1;
}
