.arf-timeline {
  --timeline-segment-width    : 168px;
  --timeline-marker-segment-count     : 4;
  --timeline-marker-width     : calc(var(--timeline-segment-width) * var(--timeline-marker-segment-count));
  --timeline-vertical-spacing : 50px;
  --timeline-center-height    : 56px;
  --timeline-center-spacing   : 50px;

  display                     : flex;
  flex-direction              : row;

  height: calc(var(--timeline-vertical-spacing) * 5.5 + var(--timeline-center-height) + var(--timeline-center-spacing) * 2);
  padding                     : 40px 20px;

  overflow-x                    : auto;

  @media (max-width: $breakpoint-small-max) {
    --timeline-vertical-spacing : 80px;
    --timeline-marker-segment-count: 3;
    height: calc(var(--timeline-vertical-spacing) * 2 + var(--timeline-center-height) + var(--timeline-center-spacing));
  }

  &__marker {
    position     : relative;
    flex-shrink  : 0;

    width        : var(--timeline-marker-width);
    margin-right : calc(var(--timeline-marker-width) * -.5);

    @media (max-width: $breakpoint-small-max) {
      margin-right: 0;
    }

    &:before {
      display    : block;
      content    : "";
      position   : absolute;
      top        : calc(var(--timeline-vertical-spacing) * 2 + var(--timeline-center-spacing));

      height     : var(--timeline-center-height);
      width      : 50%;

      @media (max-width: $breakpoint-small-max) {
        top        : calc(var(--timeline-vertical-spacing) * 1 + var(--timeline-center-spacing));
        width: 100%;
      }

      background : url("/static/arf-images/timeline-segment.svg") center left;
    }

    &:after {
      display    : block;
      content    : "";

      position   : absolute;
      top        : calc(var(--timeline-vertical-spacing) + 5px);
      left       : calc(var(--timeline-segment-width) * 6 / 7);

      width      : 1px;
      height     : calc(var(--timeline-vertical-spacing) + var(--timeline-center-spacing) - 15px);

      background : repeating-linear-gradient(
          rgba(var(--hightlight-color-rgb), 1) 0px,
          rgba(var(--hightlight-color-rgb), 1) 4px,
          rgba(var(--hightlight-color-rgb), 0) 5px,
          rgba(var(--hightlight-color-rgb), 0) 12px,
          rgba(var(--hightlight-color-rgb), 1) 13px,
          rgba(var(--hightlight-color-rgb), 1) 16px);

      @media (max-width: $breakpoint-small-max) {
        height     : calc(var(--timeline-center-spacing) - 15px);
      }
    }

    @media (min-width : $breakpoint-medium) {
      &:nth-child(4n+2):after {
        top    : calc(var(--timeline-vertical-spacing) * 2 + var(--timeline-center-spacing) + var(--timeline-center-height) + 10px);
        height : calc(var(--timeline-center-height) - 30px);
      }

      &:nth-child(4n+3):after {
        top    : calc(var(--timeline-vertical-spacing) * 2 + 5px);
        height : calc(var(--timeline-center-spacing) - 15px);
      }

      &:nth-child(4n+4):after {
        top    : calc(var(--timeline-vertical-spacing) * 2 + var(--timeline-center-spacing) + var(--timeline-center-height) + 10px);
        height : calc(var(--timeline-vertical-spacing) + var(--timeline-center-spacing) - 25px)
      }
    }

    &:last-child {
      &:before {
        width : 125%;
      }
    }

    &__info {
      display               : grid;
      grid-template-columns : min-content auto;
      align-items           : start;

      position: relative;
      z-index : 1;
      width                 : 100%;
      margin-left           : calc(var(--timeline-segment-width) * .6);

      background-color: #FFFFFF;;

      @media (max-width: $breakpoint-small-max) {
        grid-template-columns: 1fr;
        grid-auto-rows: auto;
        justify-items: start;

        padding-right: 20px;
      }
    }

    @media (min-width : $breakpoint-medium) {
      &:nth-child(4n+2) &__info {
        margin-top : calc(var(--timeline-vertical-spacing) * 2 + var(--timeline-center-height) + var(--timeline-center-spacing) * 2);
      }

      &:nth-child(4n+3) &__info {
        margin-top : var(--timeline-vertical-spacing);
      }

      &:nth-child(4n+4) &__info {
        margin-top : calc(var(--timeline-vertical-spacing) * 3 + var(--timeline-center-height) + var(--timeline-center-spacing) * 2);
      }
    }

    &__date {
      padding       : .1ch 0;
      border-top    : 1px solid var(--dark-blue);
      border-bottom : 1px solid var(--dark-blue);

      color         : var(--dark-blue);
      font-family   : var(--serif-font), serif;
      font-weight   : 700;
      font-size     : 3.2rem;
    }

    &__desc {
      &, p {
        margin : 0 0 0 10px;
        line-height : 1.2em;
        font-weight : 300;

        max-width: 90vw;

        @media (max-width: $breakpoint-small-max) {
          margin-left: 0;
          margin-top: 5px;
        }
      }
    }
  }
}
