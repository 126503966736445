.arf-jumbo-cover {
  --grid-lines-size      : 120%;
  --grid-lines-offset    : -10%;
  --background-color     : var(--dark-blue);
  --background-color-rgb : var(--dark-blue-rgb);

  position               : relative;
  width                  : 100%;

  color                  : var(--secondary-text-light);

  overflow               : hidden;

  background-color       : var(--background-color);

  &.dark-background {
    --background-color     : var(--background-color-dark);
    --background-color-rgb : var(--background-color-dark-rgb)
  }

  &.black-background {
    --background-color     : #000000;
    --background-color-rgb : 0, 0, 0;
  }

  &.encroach {
    padding-bottom : 100px;
  }

  &__background {
    position            : absolute;
    top                 : 0;
    left                : 0;
    width               : 100%;
    height              : 100%;
    max-height          : 100vh;

    background-size     : cover;
    background-position : center top;
    background-repeat   : no-repeat;

    &__overlay {
      position   : absolute;
      top        : 0;
      left       : 0;
      width      : 100%;
      height     : 100%;

      background : linear-gradient(0deg,
        rgba(var(--background-color-rgb), 1) 0%,
        rgba(var(--background-color-rgb), .1) 75%,
        rgba(var(--background-color-rgb), .1) 100%
      );
    }
  }

  &.encroach &__background {
    max-height : unset;

    &__overlay {
      background : rgba(var(--background-color-rgb), .45);
    }
  }

  &__lines {
    position              : absolute;
    top                   : 0;
    left                  : var(--grid-lines-offset);
    width                 : var(--grid-lines-size);
    height                : 100%;

    display               : grid;
    grid-template-columns : repeat(8, 1fr);

    &__line {
      height           : 100%;
      width            : 1px;
      background-color : rgba(255, 255, 255, .05);
    }

    @media(max-width : $breakpoint-xsmall-max) {
      grid-template-columns : repeat(6, 1fr);

      &__line:nth-child(1),
      &__line:nth-child(2) {
        display : none;
      }
    }
  }

  &__section {
    position              : relative;
    margin-left           : var(--grid-lines-offset);
    width                 : var(--grid-lines-size);

    display               : grid;
    grid-template-columns : repeat(8, 1fr);
    grid-auto-rows        : auto;

    @media(max-width : $breakpoint-xsmall-max) {
      grid-template-columns : repeat(6, 1fr);
    }
  }

  &__section + &__section {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background: linear-gradient(0deg,
        rgba(var(--background-color-rgb), .1) 0%,
        rgba(var(--background-color-rgb), 1) 90%,
        rgba(var(--background-color-rgb), 1) 100%
      );
    }

    > * {
      position: relative;
    }
  }

  &__top {
    padding     : 200px 0 140px 0;
    align-items : end;

    &.full {
      padding : 0;
      height  : 100vh;
    }

    @media(max-width : $breakpoint-xsmall-max) {
      padding     : 150px 0 100px 0;
      display     : block;
      width       : 100%;
      margin-left : 0;
    }

    &__content {
      grid-row        : 1;
      grid-column     : 3 / span 4;
      height          : 100%;

      display         : flex;
      flex-direction  : column;
      justify-content : flex-end;

      @media(max-width : $breakpoint-xsmall-max) {
        justify-content : center;
        margin          : 0;
        padding         : 0 25px;

        text-align      : center;
      }
    }

    &.full &__content {
      margin-bottom : 100px;
    }

    &__page-name {
      border-left : 1px solid var(--secondary-text-light);
      padding     : 0 0 0 20px;

      font-size   : 1.2rem;
      line-height : 1.2em;

      @media(max-width : $breakpoint-xsmall-max) {
        border-left : none;
        padding     : 0;
      }
    }

    h1 {
      margin  : .2ch 0 0 0;
      padding : 0 0 0 20px;
      color   : var(--primary-text-light);

      @media(max-width : $breakpoint-xsmall-max) {
        margin  : .5ch 0 0 0;
        padding : 0;
      }
    }

    &__scroll-arrow {
      grid-row         : 1;
      grid-column      : 7;
      justify-self     : end;

      appearance       : unset;
      width            : 24px;
      height           : 24px;

      margin-bottom    : 100px;

      border           : none;
      background-color : var(--primary-text-light);
      mask-image       : url("/static/arf-images/arrow-down.svg");
      mask-position    : center center;
      mask-repeat      : no-repeat;
      mask-size        : contain;

      cursor: pointer;

      transition-duration: var(--transition-duration-default);

      &:hover {
        transform: translateY(5px);
      }

      @media(max-width : $breakpoint-xsmall-max) {
        display : none;
      }
    }
  }

  &.flush-left &__top {
    @media (max-width : $breakpoint-xsmall-max) {
      display     : grid;
      width       : var(--grid-lines-size);
      margin-left : var(--grid-lines-offset);


      &__content {
        grid-column     : 2 / -2;
        justify-content : flex-end;

        padding         : 0;
        text-align      : left;
      }
    }
  }

  .text-section-small-title {
    padding-left : 20px;

    border-left  : 1px solid var(--gray-500);
    line-height  : 1.2em;
  }

  &__bottom-1 {
    padding-bottom : 50px;

    &__leading-caption {
      grid-column : 2;
      grid-row    : 1;
      padding     : 0 0 0 20px;

      font-size   : 1.2rem;

      color       : var(--tertiary-text-light);

      @media(max-width : $breakpoint-xsmall-max) {
        grid-column  : 2 / span 4;
        grid-row     : 2;

        margin-top   : 125px;
        padding-left : 0;

        text-align   : center;
      }
    }

    &__title {
      grid-column  : 3 / span 2;
      grid-row     : 1;

      margin       : 0 0 100px 0;
      color        : var(--primary-text-light);

      @media(max-width : $breakpoint-xsmall-max) {
        grid-column  : 2 / span 4;
        grid-row     : 3;

        margin       : 0 0 50px 0;
        padding-left : 0;

        text-align   : center;
      }
    }

    &__text {
      grid-column  : 3 / span 3;
      grid-row     : 1;

      margin       : 0 0 25px 0;
      padding-left : 20px;

      @media(max-width : $breakpoint-xsmall-max) {
        grid-column  : 2 / span 4;
        grid-row     : 4;

        padding-left : 0;
        text-align   : center;
      }

      p {
        font-size   : 1.6rem;
        line-height : 135%;

        + p {
          margin-top : 3rem;
        }
      }

      p + a {
        display    : inline-block;
        margin-top : 25px;
      }

      .rich-text {
        a {
          @extend .text-upper-mono;
          color: #fff;
          text-decoration: none;
        }
      }

      .cta {
        margin-top: 20px;
      }
    }

    &__socials {
      grid-column    : 7;
      grid-row       : 1;

      display        : flex;
      flex-direction : column;

      margin-left    : 20px;

      @media(max-width : $breakpoint-xsmall-max) {
        grid-column : 2 / span 4;
        grid-row    : 1;

        align-items : center;
      }

      &__label {
        color : var(--tertiary-text-light)
      }

      &__socials {
        .arf-socials {
          margin-top : 60px;
        }
      }
    }
  }

  &__bottom-2 {
    padding: 30px 0 100px;

    background-position: center center;
    background-size: cover;

    &__image {
      grid-column: 3 / span 3;

      width: 100%;
      padding-left: 20px;
      padding-right: 10%;

      @media (max-width: $breakpoint-medium-max) {
        grid-column: 2 / span 3;
      }

      @media (max-width: $breakpoint-small-max) {
        grid-column: 3 / span 4;
      }

      @media (max-width: $breakpoint-xsmall-max) {
        padding: 0;
        grid-column: 2 / span 4;
      }

      img {
        max-width : 100%;
        border-radius: 4px;
      }
    }

    &__content {
      grid-column  : -4 / span 2;

      padding-left : 20px;

      @media (max-width: $breakpoint-medium-max) {
        grid-column: 5 / span 3;
      }

      @media (max-width: $breakpoint-small-max) {
        grid-column: 3 / span 4;

        margin-top: 40px;
      }

      @media (max-width: $breakpoint-xsmall-max) {
        grid-column: 2 / span 4;

        margin-top: 40px;
        padding-left : 0;
      }

      .text-section-small-title {
        @media (max-width: $breakpoint-xsmall-max) {
          margin: 0;
          padding: 0;
          border: none;
          text-align: center;
        }
      }

      h2 {
        margin-top : 10px;
        color: #FFFFFF;
        font-weight: 300;

        @media (max-width: $breakpoint-xsmall-max) {
          text-align: center;
        }
      }

      p {
        @media (max-width: $breakpoint-xsmall-max) {
          text-align: center;
        }
      }
    }
  }

  &__last-update {
    grid-column  : 3 / span 4;
    margin       : 35px 0;
    padding-left : 20px;

    color        : var(--gray-900);
    font-weight  : 500;

    &__date {
      font-weight : 400;
    }


    @media(max-width : $breakpoint-xsmall-max) {
      border-left : none;
      padding     : 0;
    }
  }

  &__vessel {
    &__info {
      &.wide {
        grid-column-end : span 2;
      }

      &:first-child {
        grid-column-start : 3;
      }

      @media (max-width : $breakpoint-large-max) {
        grid-column-end : span 2;
      }

      @media (max-width : $breakpoint-xsmall-max) {
        grid-column : 2 / -2;

        &:first-child, &.wide {
          grid-column : 2 / -2;
        }
      }

      @media (max-width : $breakpoint-xsmall-max) {
        .text-section-small-title {
          margin-left  : 0;
          padding-left : 0;
          border-left  : none;
          font-size    : 1rem;
        }

        &:not(:first-child) {
          margin-top : 25px;
        }
      }

      h4 {
        margin      : 2rem 0 1rem 20px;
        color       : var(--secondary-text-light);
        font-weight : 300;

        @media (max-width : $breakpoint-xsmall-max) {
          margin : .5rem 0 1rem 0;
        }
      }
    }

    .primary-button {
      grid-row     : 2;
      grid-column  : 3 / span 3;
      justify-self : start;

      margin       : 50px 0 75px 20px;

      @media (max-width : $breakpoint-xsmall-max) {
        grid-row    : unset;
        grid-column : 2 / -2;

        width       : 100%;
        margin-top  : 30px;
        margin-left : 0;
        padding     : 2ch 25px;

        text-align  : center;
        font-size   : 1.4rem;
      }
    }
  }

  &__lab {
    &__info {
      grid-column   : 3 / -3;

      margin-bottom : 75px;
      padding-left  : 20px;

      @media (max-width : $breakpoint-xsmall-max) {
        grid-column  : 2 / -2;

        padding-left : 0;
      }

      .text-section-small-title {
        margin-left : -20px;

        @media (max-width : $breakpoint-xsmall-max) {
          margin-left  : 0;
          padding-left : 0;
          border-left  : none;
          font-size    : 1rem;
        }
      }

      .primary-button {
        margin-top : 40px;

        @media (max-width : $breakpoint-xsmall-max) {
          grid-row    : unset;
          grid-column : 2 / -2;

          width       : 100%;
          margin-top  : 30px;
          margin-left : 0;
          padding     : 2ch 25px;

          text-align  : center;
          font-size   : 1.4rem;
        }
      }
    }
  }

  &__ice-border {
    width               : 100%;
    height              : 46px;

    background-image    : url("/static/arf-images/ice-border.png");
    background-position : left center;
  }
}
