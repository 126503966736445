.arf-news-grid {
  grid-column           : 2 / -2;
  width                 : 100%;
  margin                : 50px 0;

  display               : grid;
  grid-template-columns : 1fr 1fr;
  grid-auto-rows        : 150px;
  grid-gap              : 20px;
  grid-auto-flow        : dense;

  @media (max-width : $breakpoint-medium-max) {
    grid-column           : 1 / -1;
    width                 : 90%;
    margin                : 50px 5%;
  }

  @media (max-width : $breakpoint-small-max) {
    grid-template-columns : 1fr;
    grid-auto-rows        : 490px;
    margin                : 0;
    width                 : 100%;
  }

  &__item {
    display       : block;
    position      : relative;
    width         : 100%;
    height        : 100%;

    border-radius : 4px;

    cursor        : pointer;

    &:first-child {
      grid-column : 1 / span 2;
      grid-row    : 1 / span 3;
    }

    &:nth-child(2) {
      grid-column : 1 / span 1;
      grid-row    : span 3;
    }

    @media (max-width : $breakpoint-small-max) {
      &:first-child {
        grid-column : 1 / span 1;
        grid-row    : 1 / span 1;
      }

      &:nth-child(2) {
        grid-column : 1 / span 1;
        grid-row    : span 1;
      }
    }

    &__image {
      display             : block;
      width               : 100%;
      height              : 100%;

      object-fit          : cover;
      transition-duration : var(--transition-duration-default);

      border-radius       : 4px;

      @media (max-width : $breakpoint-small-max) {
        border-radius: 0;
      }
    }

    &__overlay {
      position        : absolute;
      top             : 0;
      left            : 0;

      width           : 100%;
      height          : 100%;

      display         : flex;
      flex-direction  : column;
      justify-content : flex-end;

      padding         : 0 40px 20px;


      @media (max-width : $breakpoint-small-max) {
        padding-bottom: 25px;
      }

      &:before {
        content          : "";
        display          : block;

        position         : absolute;
        z-index          : 1;
        left             : 40px;
        top              : 0;
        height           : 100%;

        width            : 1px;
        background-color : rgba(var(--gray-900-rgb), .2);
      }

      &__blur {
        position        : absolute;
        left            : 0;
        bottom          : 0;

        width           : 100%;
        height          : 75%;
        backdrop-filter : blur(40px) brightness(85%);

        mask            : linear-gradient(transparent, rgb(0, 0, 0, .7) 35%, black 75%, black 100%);
        border-radius   : 4px;
      }

      &__content {
        position : relative;

        .text-section-small-title {
          margin-left   : 0;
          margin-bottom : 10px;
          padding-left  : 20px;

          border-left   : 1px solid var(--gray-900);
          color         : var(--gray-900);

          line-height   : 1.2em;
        }

        h2 {
          color     : var(--primary-text-light);
          font-size : 1.6rem;
          padding-left: 20px;

          @media (max-width : $breakpoint-xsmall-max) {
            margin-left : 20px;
          }
        }
      }
    }

    &:first-child &__overlay {
      padding-bottom : 25px;

      &__blur {
        height : 35%;
      }

      &__content {
        .text-section-small-title {
          margin-bottom : 15px;

        }

        h2 {
          font-size : 3.2rem;

          @media (max-width : $breakpoint-small-max) {
            font-size : 1.6rem;
          }
        }
      }
    }

    &:nth-child(2) &__overlay {
      &__blur {
        height : 40%;
      }

      &__content h2 {
        font-size : 2.4rem;

        @media (max-width : $breakpoint-small-max) {
          font-size : 1.6rem;
        }
      }
    }

    &:hover {
      transform : scale(1.005);
    }
  }
}
