.arf-reach-us {
  margin-top  : 70px;
  line-height : 1.65em;

  @media (max-width : $breakpoint-xsmall-max) {
    margin-top: 75px;
    grid-row-gap : 20px;
  }

  h2 {
    grid-column : span 2;
    padding-left: 20px;

    @media (max-width : $breakpoint-medium-max) {
      grid-column: 1
    }

    @media (max-width : $breakpoint-xsmall-max) {
      grid-column : span 1;
      margin-bottom: 15px;
    }
  }

  p {
    margin      : 0;
    font-weight : 300;
    line-height : 1.65em;
  }
}
