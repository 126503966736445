@charset "utf-8";

/**
 * The Basics
 */
@import "basics/responsive";
@import "basics/colors";
@import "basics/animations";
@import "basics/text";
@import "basics/body";
@import "js-datepicker/dist/datepicker.min.css";

/**
 * Components
 */
@import "components/header";
@import "components/footer";
@import "components/datepicker";
@import "components/buttons";
@import "components/socials";
@import "components/form";
@import "components/misc";
@import "components/table";
@import "components/timeline";

@import "components/pages";
@import "components/featured";
@import "components/reach-us";
@import "components/jumbo_cover";
@import "components/stat_grid";
@import "components/faq";
@import "components/contact";
@import "components/news_grid";
@import "components/history";

