/* Import Hepta Slab & Karla */
@import url('https://fonts.googleapis.com/css2?family=Hepta+Slab:wght@1..900&family=Karla:ital,wght@0,200..800;1,200..800&display=swap');

:root {
  --serif-font: "Hepta Slab";
  --sans-serif-font: "Karla";

  font-size: 62.5%;
}

h1 {
  margin: 0;

  font-family: var(--serif-font), serif;
  font-weight: 400;
  font-size: 6.4rem;
  line-height: 8.0rem;

  @media(max-width: $breakpoint-medium-max) {
    font-size: 3.2rem;
    font-weight: 400;
    line-height: 4rem;
  }
}

h2 {
  margin: 0;

  font-family: var(--serif-font), serif;
  font-weight: 400;
  font-size: 5rem;
  line-height: 1.1255em;

  @media(max-width: $breakpoint-medium-max) {
    font-size: 3.2rem;
    font-weight: 400;
    line-height: 1.25em;
  }

  @media(max-width: $breakpoint-xsmall-max) {
    margin-left: 0;
  }
}

h3 {
  margin: 0;

  font-family: var(--serif-font), serif;
  font-weight: 400;
  font-size: 2.4rem;
}

h4 {
  margin: 0;

  font-family: var(--sans-serif-font), sans-serif;;
  font-weight: 400;
  font-size: 2.4rem;
}

h5 {
  margin: 0 0 5px 0;

  font-family: var(--sans-serif-font), sans-serif;;
  font-weight: 500;
  font-size: 1.6rem;
}

h6 {
  margin: 0;

  font-family: var(--sans-serif-font), sans-serif;;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.166rem;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--secondary-text-dark);

  &.light {
    color: var(--primary-text-light);
  }
}

body, p {
  font-family: var(--sans-serif-font), sans-serif;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1.8em;
}

a {
  --link-color: var(--secondary-text-dark);
  --underline-color: var(--link-color);

  border-bottom: 1px solid transparent;
  outline: none;

  color: var(--link-color);
  text-decoration: none;

  transition-duration: var(--transition-duration-default);

  &.light {
    --link-color: var(--secondary-text-light);
  }

  &.underlined {
    border-bottom-color: var(--tertiary-text-light);
  }

  &:hover, &:focus {
    border-bottom-color: var(--underline-color);
    color: inherit;
    text-decoration: none;
  }

  &:active {
    border-bottom-color: var(--hightlight-color);
    color: inherit;
    text-decoration: none;
  }
}

.text-upper-mono {
  letter-spacing: .2em;
  text-transform: uppercase;
}

.text-light {
  font-weight: 300;
}

.text-strong {
  font-weight: 500;
}

.text-emphasis {
  font-weight: 300;
  font-style: italic;
}

.text-section-small-title {
  --border-color: var(--gray-700);
  color: var(--gray-500);

  text-transform: uppercase;
  letter-spacing: .18em;
  font-size: 1.2rem;

  @media(max-width: $breakpoint-xsmall-max) {
    margin-left: 0;
  }

  &.left-bar {
    padding-left: 20px;
    border-left: 1px solid var(--border-color);
  }

  &.left-offset {
    margin-left: -20px;
  }

  p {

  }
}
