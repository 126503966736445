
.qs-datepicker-container {
  width: 100%;
  font-size: 1.2rem;

  .qs-square {
    height: auto;
  }
}


