form {
  input, select, textarea {
    appearance: none;
    padding     : 10px;

    outline     : none;
    border      : 1px solid var(--gray-800);
    border-radius: 0;

    background-color: #FFFFFF;

    font-family : var(--sans-serif-font), sans-serif;

    transition-duration : var(--transition-duration-default);

    &:hover {
      border-color : var(--gray-500);
    }

    &:active, &:focus {
      border-color : var(--hightlight-color);
    }
  }

  textarea {
    min-height: 200px;
  }

  input[type=button],
  input[type=submit],
  input[type=reset] {
    padding          : 10px 25px;

    border           : 1px solid var(--gray-800);
    background-color : #FFFFFF;

    text-transform   : uppercase;
    font-size        : 1rem;
    font-family      : var(--sans-serif-font), sans-serif;
  }

  .form-field {
    width          : 100%;

    display        : flex;
    flex-direction : column;

    &.wide {
      grid-column: span 2;
    }

    label {
      font-weight : 500;
      color       : var(--dark-blue);
    }

    input, select, textarea {
      width : 100%;
    }
  }
}

.custom-form {
  .form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px 20px;
    align-items: start;
    justify-items: start;

    @media (max-width: $breakpoint-medium-max) {
      grid-column: 1 / -1;
      grid-template-columns: 1fr 1fr;
      padding: 0 15%;
    }

    @media (max-width: $breakpoint-small-max) {
      padding: 0 10%;
    }

    @media (max-width: $breakpoint-xsmall-max) {
      padding: 0;
      grid-template-columns: 1fr;

      .wide {
        grid-column: span 1;
      }
    }
  }
}

.form-message {
  margin-top: 10px;
  display: none;

  &.error {
    color: #ee0000;
  }

  &.success {
    color: #00aa00;
  }
}
