.badge {
  display: inline-block;
  margin: 0 2ch;
  box-shadow: 0 0 0 1.2ch var(--hightlight-color);

  border-radius: .5px;
  background-color: var(--hightlight-color);
  color: var(--dark-blue);

  text-transform: uppercase;
  font-size: .625em;
  line-height: .6em;
  letter-spacing: .18em;
  font-weight: 400;

  vertical-align: middle;
}

.primary-button {
  appearance: unset;

  display: inline-block;
  padding: 1.5ch 25px;

  border-radius: 2px;
  border: none;
  background-color: var(--hightlight-color);
  color: var(--dark-blue);

  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.2rem;
  letter-spacing: .18em;
  font-weight: 400;

  transition-duration: var(--transition-duration-default);

  &:hover {
    filter: brightness(110%);
  }

  &:active {
    filter: brightness(90%);
  }
}

.visibility_hidden {
  visibility: hidden;
  transition: none;
}
