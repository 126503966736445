.arf-socials {
  display        : flex;
  flex-direction : row;
  flex-wrap      : wrap;
  gap            : 1.6rem;

  --socials-color: var(--primary-text-light);

  @media(max-width : $breakpoint-xsmall-max) {
    margin-top : 30px;
  }

  a {
    display          : block;
    width            : 2.5rem;
    height           : 2.5rem;

    background-color : var(--socials-color);

    mask-position    : center center;
    mask-size        : contain;
    mask-repeat      : no-repeat;

    &:hover {
      background : var(--secondary-text-light);
    }

    &.facebook {
      mask-image : url("/static/arf-images/socials/fb.svg");
    }

    &.instagram {
      mask-image : url("/static/arf-images/socials/insta.svg");
    }

    &.twitter {
      mask-image : url("/static/arf-images/socials/twitter.svg");
    }

    &.youtube {
      mask-image : url("/static/arf-images/socials/yt.svg");
    }

    &.linkedin {
      mask-image : url("/static/arf-images/socials/linkedin.svg");
    }

    &.tiktok {
      mask-image : url("/static/arf-images/socials/tiktok.svg");
    }

    &:hover {
      background-color: var(--socials-color);
      opacity: .65;

      border: none;
    }
  }
}
