.arf-footer {
  grid-row-gap          : 40px;
  justify-items         : start;
  align-items           : start;

  margin-top: 40px;

  padding-top: 50px;
  padding-bottom: 50px;

  color                 : var(--dark-blue);

  @media (max-width : $breakpoint-xsmall-max) {
    grid-template-columns : repeat(1, 1fr);
    grid-row-gap          : 30px;
  }

  &__logo-link {
    border : none !important;
  }

  &__logo {
    width            : 60px;
    height           : 60px;

    background-color : var(--background-color-dark);

    mask-image       : url("/static/arf-images/bear.svg");
    mask-position    : center center;
    mask-size        : contain;
    mask-repeat      : no-repeat;
  }

  h5 {
    margin : 0 0 1.5rem 0;
  }

  &__newsletter {
    grid-column  : span 2;
    justify-self : stretch;

    padding-left: 20px;

    @media (max-width: $breakpoint-medium-max) {
      grid-column: span 2;
      padding-left: 0;
    }

    @media (max-width: $breakpoint-xsmall-max) {
      grid-column: span 1;
    }

    form {
      display        : flex;
      flex-direction : row;
      gap            : 1ch;

      padding        : 0 15px 0 0;

      input[type=email] {
        flex        : 1;
        max-width   : 230px;
      }
    }
  }

  &__links {
    display        : flex;
    flex-direction : column;
    align-items    : flex-start;

    a {
      font-size   : 1.6rem;
      font-weight : 300;
    }
  }

  &__copyright {
    grid-column    : span 3;

    line-height    : 1rem;
    font-size      : 1.2rem;
    font-weight    : 300;

    @media (max-width: $breakpoint-medium-max) {
      grid-row: 4;
    }

    @media (max-width: $breakpoint-xsmall-max) {
      grid-column: span 1;
      grid-row: 8;
    }
  }

  &__legals {
    align-self  : center;
    grid-column: span 2;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: start;
    width: 100%;

    @media (max-width: $breakpoint-medium-max) {
      grid-row: 3;
    }

    @media (max-width: $breakpoint-xsmall-max) {
      grid-column: span 1;
      grid-row: 7;
    }

    a {
      align-self  : center;

      line-height : 1.1rem;
      font-size   : 1.2rem;
      font-weight : 300;
    }
  }

  &__socials {
    @media (max-width: $breakpoint-medium-max) {
      grid-row: 3;
    }

    @media (max-width: $breakpoint-xsmall-max) {
      grid-row: 6;
    }

    .arf-socials {
      --socials-color : var(--dark-blue);
    }
  }
}
