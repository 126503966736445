.arf-table {
  display    : grid;
  width      : 100%;

  @media (max-width : $breakpoint-xsmall-max) {
    grid-template-columns : 100%;
    margin-top            : 20px;
  }

  &__header {
    display : contents;

    @media (max-width : $breakpoint-xsmall-max) {
      display : none;
    }

    &__cell {
      width          : 100%;
      padding        : 10px 20px 10px 0;

      color          : var(--gray-500);
      text-transform : uppercase;
      text-align     : left;

      font-size      : 1.2rem;
      font-weight    : 400;
      letter-spacing : .18em;
    }
  }

  &__body {
    display : contents;
    color   : var(--dark-blue);

    &__row {
      display     : contents;

      grid-column : 1 / -1;
    }

    &__cell {
      vertical-align : top;
      padding        : 15px 20px 15px 0;

      border-bottom  : 1px solid var(--gray-900);
      font-weight    : 400;

      @media (max-width : $breakpoint-xsmall-max) {
        padding : 0;
        border  : none;

        &:first-child {
          margin-top : 45px;
        }

        &:last-child {
          padding-bottom : 15px;
          border-bottom  : 1px solid var(--gray-100);
        }
      }

      &.title {
        font-weight : 500;
        font-size   : 2.4rem;
      }

      p {
        margin      : 0;
        font-weight : 300;

        @media (max-width : $breakpoint-xsmall-max) {
          margin : 25px 0;
        }
      }

      .cta {
        font-size: 1.1rem;
        text-align: center;
      }

      .primary-button {
        @media (max-width : $breakpoint-xsmall-max) {
          width   : 100%;
          padding : 15px 25px;
        }
      }
    }
  }

  &__footer {
    display : contents;
  }

  &__paginator {
    grid-column    : 1 / -1;

    display        : flex;
    flex-direction : row;
    gap            : 1ch;

    padding        : 15px 0;

    @media (max-width : $breakpoint-xsmall-max) {
      display: none;
    }

    &__page-button {
      --button-color   : var(--gray-900);
      appearance       : none;

      min-width        : 3.2rem;
      height           : 3.2rem;

      border           : 2px solid transparent;
      background-color : transparent;

      color            : var(--dark-blue);
      text-align       : center;
      font-size        : 1.2rem;

      &.prev-page, &.next-page {
        &[disabled] {
          color : var(--button-color);
        }
      }

      &.prev-page {
        margin-left : auto;
        border-color   : var(--button-color);
      }

      &.next-page {
        border-color   : var(--button-color);
      }

      &.current {
        border-color : var(--button-color);
      }

      &[disabled] {
        --button-color : var(--gray-900);
        pointer-events : none;
      }

      &:hover {
        background-color : var(--button-color);
      }

      &:active {
        border-color     : var(--button-color);
        background-color : transparent;
      }
    }
  }
}
