:root {
  /* Raw Colors */
  --primary-blue: #1E00FF;
  --primary-blue-rgb: 30, 0, 255;
  --secondary-blue: #00B2FF;
  --secondary-blue-rgb: 0, 178, 255;
  --tertiary-blue: #27D9FF;
  --tertiary-blue-rgb: 39, 217, 255;

  --dark-blue: #032236;
  --dark-blue-rgb: 3, 34, 54;
  --darkest-blue: #05151F;
  --darkest-blue-rgb: 5, 21, 31;

  --gray-100: #4E6472;
  --gray-100-rgb: 78, 100, 114;
  --gray-500: #97A1A7;
  --gray-500-rgb: 151, 161, 167;
  --gray-700: #BFBFBF;
  --gray-700-rgb: rgb(191, 191, 191);
  --gray-800: #D9D9D9;
  --gray-800-rgb: 217, 217, 217;
  --gray-900: #E5F0F7;
  --gray-900-rgb: 229, 240, 247;


  /* Semantic Colors */
  --primary-color: var(--primary-blue);
  --primary-color-rgb: var(--primary-blue-rgb);
  --secondary-color: var(--dark-blue);
  --secondary-color-rgb: var(--dark-blue-rgb);

  --hightlight-color: var(--secondary-blue);
  --hightlight-color-rgb: var(--secondary-blue-rgb);

  --background-color-light: #FFFFFF;
  --background-color-light-rgb: 255, 255, 255;
  --background-color-dark: var(--darkest-blue);
  --background-color-dark-rgb: var(--darkest-blue-rgb);


  /* On light Background */
  --primary-text-dark: #0A0F0A;
  --primary-text-dark-rgb: 10, 15, 10;
  --secondary-text-dark: #0D120D;
  --secondary-text-dark-rgb: 13, 18, 13;
  --tertiary-text-dark: var(--gray-900);
  --tertiary-text-dark-rgb: var(--gray-900-rgb);

  /* On dark background */
  --primary-text-light: #FFFFFF;
  --primary-text-light-rgb: 255, 255, 255;
  --secondary-text-light: var(--gray-900);
  --secondary-text-light-rgb: var(--gray-900-rgb);
  --tertiary-text-light: var(--gray-500);
  --tertiary-text-light-rgb: var(--gray-500-rgb);
}