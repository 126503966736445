* {
  box-sizing : border-box;
}

html, body {
  margin     : 0;
  padding    : 0;
  width      : 100%;
  height     : 100%;

  background : var(--background-color-light);
}

html {
  scroll-padding-top : 200px;

  @media (max-width : $breakpoint-xsmall-max) {
    scroll-padding-top : 60px;
  }
}

body {
  position : relative;
}

.arf-default-grid {
  position              : relative;

  display               : grid;
  grid-template-columns : repeat(6, 1fr);
  grid-auto-rows        : auto;

  width                 : 90%;
  margin-left           : 5%;
  margin-right          : 5%;

  border-radius         : 2px;
  background-color      : #FFFFFF;

  @media (max-width : $breakpoint-medium-max) {
    grid-template-columns : repeat(3, 1fr);
  }

  @media (max-width : $breakpoint-xsmall-max) {
    grid-template-columns : repeat(1, 1fr);
  }

  &.grid-full-width {
    width: 100%;
    margin: 0;

    .arf-default-grid {
      width: 100%;
      margin: 0;
    }
  }

  &.show-grid-lines {
    .arf-content__lines__line {
      z-index: 1;
    }
  }

  &.encroach {
    margin-top : -110px;
  }

  .arf-content__lines {
    position        : absolute;
    top             : 0;
    left            : 0;

    width           : calc(100% + 1px);
    height          : 100%;

    display         : flex;
    flex-direction  : row;
    justify-content : space-between;

    pointer-events  : none;

    @media (max-width : $breakpoint-medium-max) {
      display : none;
    }

    .arf-content__lines__line {
      height     : 100%;
      width      : 1px;
      background : repeating-linear-gradient(
          rgba(var(--dark-blue-rgb), .01),
          rgba(var(--dark-blue-rgb), .075),
          rgba(var(--dark-blue-rgb), .01) 1000px);
    }
  }

  .arf-big-text {
    grid-column           : 2 / -1;

    display               : grid;
    grid-template-columns : 60% 40%;

    margin                : 75px 0 50px;

    @media (max-width : $breakpoint-medium-max) {
      grid-column : 1 / -1;
    }

    @media (max-width : $breakpoint-small-max) {
      margin-top            : 50px;
      grid-template-columns : repeat(1, 1fr);
    }

    @media (max-width : $breakpoint-xsmall-max) {
      margin-top : 25px;
    }

    &__content {
      margin-top    : 25px;
      padding-right : 50px;
      padding-left: 20px;

      @media (max-width : $breakpoint-small-max) {
        padding-right : 30px;
      }

      @media (max-width : $breakpoint-xsmall-max) {
        padding : 0;
      }

      .text-section-small-title {
        margin-left  : -20px;
        padding-left : 20px;

        border-left  : 1px solid var(--gray-700);

        @media (max-width : $breakpoint-xsmall-max) {
          margin     : 0;
          padding    : 0;
          border     : none;
          text-align : center;
        }
      }

      h2 {
        margin-top  : 15px;
        font-weight : 400;

        @media (max-width : $breakpoint-xsmall-max) {
          margin-top : 10px;
          text-align : center;
        }
      }

      p {
        margin        : 40px 0 0 0;
        padding-right : 50px;

        font-weight   : 300;

        @media (max-width : $breakpoint-small-max) {
          margin-top    : 30px;
          padding-right : 30px;
        }

        @media (max-width : $breakpoint-xsmall-max) {
          margin     : 20px 0 0 0;
          padding    : 0;
          text-align : center;
        }
      }
    }

    &__image {
      width: 100%;
      height: 100%;

      @media (max-width : $breakpoint-small-max) {
        margin-top   : 40px;
      }

      @media (max-width : $breakpoint-xsmall-max) {
        transform: translateX(5vw);
      }

      img {
        width      : 100%;
        max-width  : 450px;
        height     : 100%;

        object-fit : contain;

        @media (max-width : $breakpoint-small-max) {
          width      : 100%;
          max-width: unset;
          max-height: 475px;

          object-position: center center;
        }

        @media (max-width : $breakpoint-xsmall-max) {
          object-position: 15vw 50%;
        }
      }
    }
  }

  article {
    grid-column : 2 / -2;
    padding     : 75px 0 75px 20px;

    @media (max-width : $breakpoint-medium-max) {
      grid-column : 1 / -1;
      padding     : 50px 75px;
    }

    @media (max-width : $breakpoint-small-max) {
      padding : 25px 25px;
    }

    @media (max-width : $breakpoint-xsmall-max) {
      padding : 25px 0;
    }

    h1, h2, h3 {
      margin-bottom : 1em;
    }

    p {
      line-height : 2.6rem;
    }

    .text-section-small-title {
      margin-left   : -20px;
      margin-bottom : 1.5rem;
      padding-left  : 20px;

      border-left   : 1px solid var(--gray-500);

      line-height   : 1.5em;
    }

    .rich-text + .text-section-small-title {
      margin-top : 100px;
    }

    blockquote {
      margin-left  : -20px;
      padding-left : 20px;

      border-left  : 1px solid var(--dark-blue);

      &, p {
        font-size    : 2.4rem;
        font-style   : italic;
        font-weight  : 200;
        line-height  : 1.3em;
      }

      @media (max-width : $breakpoint-small-max) {
        margin-left   : 0;
        padding       : 2rem 0;

        border-left   : none;
        border-top    : 1px solid var(--dark-blue);
        border-bottom : 1px solid var(--dark-blue);
      }
    }

    @media (max-width : $breakpoint-small-max) {
      h1, h2, h3 {
        text-align : center;
      }

      .text-section-small-title {
        text-align   : center;
        padding-left : 0;
        border-left  : none;
      }

      p + .text-section-small-title {
        margin-top : 0;

        &:before {
          content          : "";
          display          : block;
          width            : 100%;
          height           : 1px;
          margin           : 50px 0;

          background-color : var(--gray-900);
        }
      }
    }
  }

  .arf-careers {
    grid-column: 2 / -1;
    padding-left: 20px;

    @media (max-width : $breakpoint-medium-max) {
      grid-column : 1 / -1;
    }

    @media (max-width : $breakpoint-xsmall-max) {
      padding-left: 0;
    }

    .arf-table {
      grid-template-columns: auto auto 50% fit-content(10%);


      @media (max-width : $breakpoint-xsmall-max) {
        grid-template-columns : unset;
      }
    }
  }

  hr {
    border: none;
    border-top: 1px solid var(--gray-900);
    margin: 40px 0;
  }
}

.rich-text {
  a {
    font-weight: 400;
    text-decoration: underline;
  }
}

.lf-content-image-small {
  max-width: 720px;
  margin: auto;
}

b {
  font-weight: 600;
}
