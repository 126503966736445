.arf-stat-grid {
  grid-column           : 2 / -1;

  display               : grid;
  grid-template-columns : 60% 40%;
  grid-auto-rows        : auto;

  margin                : 75px 0 75px -35px;

  border-top            : 1px solid var(--gray-900);
  border-left           : 1px solid var(--gray-900);

  @media(max-width: $breakpoint-medium-max) {
    grid-column: 1 / -1;

    margin-left: 0;
  }

  @media(max-width: $breakpoint-small-max) {
    grid-template-columns: 100%;
  }

  @media(max-width: $breakpoint-xsmall-max) {
    border: none;
  }

  &__cell {
    padding       : 40px 35px;

    border-bottom : 1px solid var(--gray-900);
    border-right  : 1px solid var(--gray-900);

    color         : var(--dark-blue);
    line-height   : 1.8em;

    &.wide {
      grid-column: span 2;
    }

    @media(max-width: $breakpoint-xsmall-max) {
      margin-bottom: 50px;
      padding: 0;
      border: none;

      &.wide {
        grid-column: span 1;
      }
    }

    &__wrapper {
      border-left  : 1px solid var(--gray-900);

      padding-left : 20px;

      & > a {
        color     : var(--gray-100);
        font-size : 1.4rem;
        padding-bottom: .4ch;
      }
    }

    &.last {
      @media(max-width: $breakpoint-xsmall-max) {
        grid-row-start: 999;
      }
    }

    .text-section-small-title {
      margin-left   : -21px;
      margin-bottom : 20px;
      padding-left  : 21px;

      border-left   : 1px solid var(--dark-blue);

      color         : var(--gray-100);
      line-height   : 1.2em;
    }

    ul {
      list-style-type : none;

      margin          : 1rem 0;
      padding         : 0;
    }

    &__stats {
      display               : grid;
      grid-template-columns : 1fr 1.4fr;
      grid-auto-rows        : auto;

      &__label {
        color : var(--gray-100);
      }

      &__value {
        text-align : right;
      }
    }
  }
}
