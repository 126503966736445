/**
 * The header style is define with the default state being the header style when the page is scrolled all the way up, with no interactions having happened
 */
.arf-header {
  --header-height: 65px;
  --header-margin: 31px;
  --items-spacing: 25px;
  --border-radius: 2px;

  position: fixed;
  top: var(--header-margin);
  left: var(--header-margin);
  z-index: 1001;

  width: calc(100% - (var(--header-margin) * 2));
  min-height: var(--header-height);

  display: flex;
  flex-direction: row;
  align-items: center;

  @media(max-width : $breakpoint-large-max) {
    --header-height: 48px;
    --header-margin: 24px;
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;

    width: var(--header-height);
    height: var(--header-height);

    border: none;

    &__icon {
      width: 40px;
      height: 40px;

      @media(max-width : $breakpoint-large-max) {
        width: 30px;
        height: 30px;
      }

      background-color : var(--background-color-light);

      mask-image : url("/static/arf-images/bear.svg");
      mask-position : center center;
      mask-size : contain;
      mask-repeat : no-repeat;
    }
  }

  &__menu-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    width: 100%;
    height: var(--header-height);

    margin: 0 12px 0 0;

    border-radius: var(--border-radius);

    overflow: hidden;

    transition-duration: var(--transition-duration-default);
    transition-property: background-color;

    @media(max-width : $breakpoint-large-max) {
      margin-right: 7px;
    }

    &__group {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: var(--items-spacing);

      height: var(--header-height);
      padding: 0 var(--items-spacing);

      transition-duration: var(--transition-duration-default);
    }

    &__left-group,
    &__right-group {
      display: contents;
    }

    &__right-group>*:first-child {
      margin-left: auto;
    }

    &.isSubmenuOpen &__left-group {
      display: none;
    }

    @media(max-width : $breakpoint-medium-max) {
      &__left-group {
        display: none;
      }

      &.isSubmenuOpen &__right-group {
        display: none;
      }
    }

    @media(max-width : $breakpoint-small-max) {
      &__right-group {
        display: none;
      }

      &.isSubmenuOpen {
        display: none;
      }
    }

    &__spacer {
      flex-grow: 1;
    }

    &__main {
      flex-grow: 1;

      border-left: 1px solid transparent;
    }

    &__drawer {
      border-radius: var(--border-radius);

      &:not(.isOpen) {
        display: none;
        visibility: hidden;
      }

      &.isOpen {
        background-color: var(--gray-900);

        @media(max-width : $breakpoint-small-max) {
          display: none;
        }

        .arf-header__item {
          --item-color: var(--secondary-color)
        }
      }
    }
  }

  &__vessels-browser-button {
    @media(max-width : $breakpoint-xsmall-max) {
      margin-left: auto;
    }
  }

  &__item {
    --item-color: var(--secondary-text-light);
    --underline-color: var(--item-color);

    appearance: unset;
    display: inline-block;
    text-transform: uppercase;
    padding: .5ch 0;

    border: 1px solid transparent;
    background-color: transparent;

    color: var(--item-color);
    text-decoration: none;
    white-space: nowrap;

    font-family: var(--sans-serif-font), sans-serif;
    font-size: 1.2rem;
    line-height: 1.4rem;
    letter-spacing: .2rem;

    cursor: default;

    transition-duration: var(--transition-duration-default);

    &.underlined {
      border-bottom-color: var(--gray-700);
    }

    &:hover,
    &.isActive {
      border-bottom: .5px solid var(--item-color);
      color: var(--item-color);
    }

    &:active,
    &:focus,
    &.isActive {
      --underline-color: var(--item-color);
      color: var(--item-color);
    }
  }

  &__submenu-button {
    appearance: unset;
    width: var(--header-height);
    height: var(--header-height);

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    border: none;
    border-radius: var(--border-radius);
    background-color: transparent;

    --icon-color: var(--secondary-text-light);

    transition-duration: var(--transition-duration-default);

    &__icon {
      width: 1.6rem;
      height: 1.6rem;

      background-color: var(--icon-color);

      mask-image: url("/static/arf-images/navigation.svg");
      mask-position: center center;
      mask-size: contain;
      mask-repeat: no-repeat;

      transition-duration: var(--transition-duration-default);
    }

    &:hover {
      background-color: rgba(255, 255, 255, .25);
    }

    &:active {
      --icon-color: var(--hightlight-color);
      background-color: rgba(255, 255, 255, .35);
    }

    &.isOpen {
      background-color: var(--gray-900);
      --icon-color: var(--hightlight-color);
    }

    @media (max-width : $breakpoint-small-max) {
      &.isOpen {
        display: none;
      }
    }
  }

  &__close-button {
    position: absolute;
    top: 0;
    right: 0;

    display: none;
    flex-shrink: 0;
    align-self: center;

    width: var(--header-height);
    height: var(--header-height);

    border-radius: var(--border-radius);

    background-color: var(--dark-blue);
    background-image: url("/static/arf-images/close.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 15px 15px;
  }

  @media (min-width : $breakpoint-large) {
    &.isVesselsBrowserOpen &__logo {
      visibility: hidden;
      opacity: 0;

      pointer-events: none;
    }
  }

  &.isVesselsBrowserOpen {
    pointer-events: none;
  }

  &.isVesselsBrowserOpen &__menu-bar__main,
  &.isVesselsBrowserOpen &__submenu-button,
  &.isVesselsBrowserOpen &__menu-bar__drawer.isOpen {
    visibility: hidden;
    opacity: 0;
  }

  &.isSubmenuOpen &__close-submenu-button,
  &.isVesselsBrowserOpen &__close-vessels-browser-button {
    display: block;
    pointer-events: auto;
  }

  // -----------------------------------
  // Solid State

  &:not(.isVesselsBrowserOpen).solid &__menu-bar, &:not(.isVesselsBrowserOpen).solid &__submenu-button {
    border: 1px solid #eaeaea;
  }

  &:not(.isVesselsBrowserOpen).solid &__logo {
    &__icon {
      background-color: var(--secondary-color);
    }
  }

  &:not(.isVesselsBrowserOpen).solid &__menu-bar {
    background-color: #FFFFFF;
  }

  &:not(.isVesselsBrowserOpen).solid &__menu-bar__main {
    border-left: 1px solid var(--gray-900);
  }

  &:not(.isVesselsBrowserOpen).solid &__item {
    --item-color: var(--secondary-color);
  }

  &:not(.isVesselsBrowserOpen).solid &__submenu-button:not(.isOpen) {
    background-color: #FFFFFF;
    --icon-color: var(--secondary-color);

    &:hover {
      background-color: var(--gray-900);
    }

    &:active {
      --icon-color: var(--hightlight-color);
    }
  }


  &:not(.isVesselsBrowserOpen).solid &__menu-bar__drawer {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

// --------------------------------------------
// MARK: - Mobile Menu

.arf-mobile-menu {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 5;

  width: 100%;
  height: 100vh;
  overflow: auto;

  padding: 24px 25px 50px;

  background-color: var(--background-color-dark);

  transition-duration: var(--transition-duration-default);

  &:not(.isOpen) {
    visibility: hidden;
    opacity: 0;
    right: -5%;
  }

  @media(min-width : $breakpoint-medium) {
    display: none;
  }

  &__links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 48px;
    grid-auto-rows: auto;
    align-items: start;
    justify-items: start;
    overflow: auto;

    overscroll-behavior: contain;

    &>.wide {
      grid-column: span 2;
    }
  }

  .donate-link {
    padding-top: 1ch;

    font-size: 1.6rem;
    margin-bottom: 10px;
    align-self: center;
  }

  .text-section-small-title {
    margin-left: 0;
    margin-top: 50px;
  }

  &__link {
    text-transform: unset;
    font-size: 1.8rem;
    line-height: 2.1rem;
    letter-spacing: .02em;
    font-weight: 300;
  }
}

// --------------------------------------------
// MARK: - Vessels Browser

.arf-vessels-browser {
  visibility: hidden;
  opacity: 0;

  z-index: 1000;

  position: fixed;
  width: 100%;
  height: 100vh;

  display: grid;
  grid-template-columns: 600px auto;

  background-color: rgba(var(--darkest-blue-rgb), .5);
  transition-duration: var(--transition-duration-default);

  @media(max-width : $breakpoint-medium-max) {
    grid-template-columns: 100%;
  }

  &.isBrowserOpen {
    visibility: visible;
    opacity: 1;

    left: 0;
  }

  &.isBrowserOpen &__left-panel {
    margin-left: 0;
  }

  &__left-panel {
    display: grid;
    grid-template-columns: 160px auto;
    grid-template-rows: 90px min-content;
    grid-auto-rows: auto;

    margin-left: -5%;
    padding-top: 50px;

    background-color: var(--darkest-blue);

    overflow: auto;
    overscroll-behavior: contain;
    transition-duration: var(--transition-duration-default);

    @media(max-width : $breakpoint-medium-max) {
      padding-top: 100px;
      grid-template-columns: 130px auto;
    }

    @media(max-width : $breakpoint-xsmall-max) {
      grid-template-columns: 100%
    }

    &__filters {
      grid-column: 2;
      align-self: start;
      min-height: 0;

      display: flex;
      flex-direction: row;
      gap: 25px;

      padding: 0;
      margin: 0 0 50px 0;

      list-style-type: none;

      @media(max-width : $breakpoint-xsmall-max) {
        grid-column: 1;
        margin-left: 30px;

        .arf-header__item {
          font-size: 1.6rem;
        }
      }
    }

    &__section-label {
      margin-left: 60px;

      @media(max-width : $breakpoint-medium-max) {
        margin-left: 30px;
      }

      @media(max-width : $breakpoint-xsmall-max) {
        margin-bottom: 20px;

        &.arf-vessels-browser__filters-section {
          display: none;
        }
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 50px;

      margin-bottom: 75px;

      @media(max-width : $breakpoint-xsmall-max) {
        gap: 15px;

        margin-left: 30px;
        margin-bottom: 75px;
      }
    }

    &__separator {
      grid-column: span 2;
      width: 100%;
      margin-bottom: 75px;
      border: none;

      @media(max-width : $breakpoint-xsmall-max) {
        grid-column: 1;
      }
    }

    a {
      border-bottom: none;

      h3 {
        opacity: .45;

        color: var(--secondary-text-light);
        font-weight: 300;

        transition-duration: var(--transition-duration-default);
      }

      &:hover,
      &:focus {
        h3 {
          opacity: 1;
        }
      }

      &:active {
        h3 {
          opacity: 1;
          color: var(--hightlight-color);
        }
      }
    }
  }

  &__preview-panel {
    position: relative;

    display: none;
    flex-direction: column;

    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    &.isVisible {
      display: flex;

      @media(max-width : $breakpoint-medium-max) {
        display: none;
      }
    }

    &__info {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-auto-rows: auto;
      grid-row-gap: 30px;
      grid-column-gap: 1ch;
      align-items: end;

      margin-top: auto;

      padding: 150px 50px 60px 50px;

      background: linear-gradient(0deg,
          rgba(var(--background-color-dark-rgb), 1) 0%,
          rgba(var(--background-color-dark-rgb), .1) 80%,
          rgba(var(--background-color-dark-rgb), 0) 100%,
        );

      @media(max-width : $breakpoint-large-max) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &__title {
      grid-column: span 4;
      margin: 0;

      color: var(--primary-text-light);
      font-family: var(--sans-serif-font), sans-serif;
      font-size: 3.2rem;
      line-height: unset;

      @media(max-width : $breakpoint-large-max) {
        grid-column: span 2;
      }
    }

    &__rent-button {
      grid-column: span 2;

      justify-self: end;

      @media(max-width : $breakpoint-large-max) {
        grid-row: 3;
        grid-column: 2;
      }
    }

    &__stat {
      display: flex;
      grid-column: span 2;
      align-items: last baseline;

      color: var(--secondary-text-light);
      text-transform: uppercase;

      @media(max-width : $breakpoint-large-max) {
        grid-column: span 1;
      }

      .stat-title {
        margin-right: 20px;

        font-size: 1.2rem;
        letter-spacing: .18em;
        line-height: 1.2rem;
      }

      .stat-value {
        margin-right: 10px;

        white-space: nowrap;

        font-size: 2.4rem;
        font-weight: 500;
        line-height: 1.2rem;
      }

      .stat-unit {
        font-size: 1.2rem;
        letter-spacing: .18em;
        white-space: nowrap;
      }

      .label-group {
        display: flex;
        flex-direction: column;
        align-self: flex-end;
        margin-right: 20px;
        font-size: 1.2rem;
        letter-spacing: 0.18em;
        line-height: 1.2rem;
      }

      .stat-group {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }

    &__map-link {
      grid-column: span 2;
      justify-self: end;

      --link-color: var(--secondary-text-light);

      line-height: 2rem;
      letter-spacing: .18em;
      text-transform: uppercase;

      @media(max-width : $breakpoint-large-max) {
        grid-column: 1;
        justify-self: start;
      }
    }
  }
}
