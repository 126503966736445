.arf-featured {
  width        : 95%;
  margin-right : 0;
  margin-top   : 100px;

  @media (max-width : $breakpoint-xsmall-max) {
    grid-column : 1 / -1;

    width       : 100%;
    margin-left : 0;

    margin-top  : 75px;
  }

  .text-section-small-title {
    @media (max-width : $breakpoint-xsmall-max) {
      margin-left : 5%;
    }
  }

  h2 {
    grid-column : 2 / -1;

    @media (max-width : $breakpoint-xsmall-max) {
      grid-column : 1 / -1;
      margin-left : 5%;
    }
  }

  &__scroll-controls {
    @media (max-width : $breakpoint-xsmall-max) {
      display : none;
    }

    align-self : end;
    padding-bottom: 20px;

    button {
      appearance       : unset;
      width: 51px;
      height: 51px;

      border: none;
      border-radius: 3px;
      background-color : var(--dark-blue);
      background-position: center center;
      background-repeat: no-repeat;

      color            : #FFFFFF;

      &.prev {
        background-image : url("/static/arf-images/arrow-left.svg");
      }

      &.next {
        background-image : url("/static/arf-images/arrow-right.svg");
        margin-left: 10px;
      }

      &:hover {
        opacity: .9;
      }

      &:active {
        opacity: .8;
      }
    }
  }

  &__list {
    grid-column    : 2 / -1;

    display        : flex;
    flex-direction : row;
    gap            : 15px;

    overflow       : auto;

    padding        : 25px 0;
    scroll-snap-type: x mandatory;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    & {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }

    @media (max-width : $breakpoint-xsmall-max) {
      grid-column : 1 / -1;

      scroll-padding: 5vw;
    }

    &__item {
      --underline-color: var(--gray-900);

      flex-shrink    : 0;
      display        : flex;
      flex-direction : column;

      width          : 374px;
      padding        : 8px;

      border         : 1px solid var(--gray-900);

      scroll-snap-align: start;

      @media (max-width : $breakpoint-small-max) {
        max-width : 80%;
      }

      @media (max-width : $breakpoint-xsmall-max) {
        &:first-child {
          margin-left : 5vw;
        }
      }

      &:last-child {
        margin-right : 5vw;
      }

      &__image {
        width               : 100%;
        height              : 368px;

        background-position : center center;
        background-size     : cover;

        &__overlay {
          opacity             : 0;

          width               : 100%;
          height              : 100%;

          display             : flex;
          flex-direction      : column;

          padding             : 5px 5px;

          background-color    : rgba(var(--dark-blue-rgb), .5);
          backdrop-filter     : grayscale(1);

          transition-duration : var(--transition-duration-default);

          p {
            margin      : 0;
            padding     : 5px;
            color       : #FFFFFF;
            font-size   : 1.2rem;
            line-height : 1.4em;
          }

          button {
            margin-top : auto;
            padding    : 12px;

            color      : #FFFFFF;
          }
        }
      }

      &:hover &__image__overlay {
        opacity : 1;
      }

      &__text {
        margin-top  : 8px;

        line-height : 1em;
        color       : var(--dark-blue);

        &__desc {
          text-transform : uppercase;

          font-size      : 1.2rem;
          font-weight    : 300;
        }
      }
    }
  }
}
