.btn-custom-1 {
  --border-color: var(--gray-800);

  padding: 10px 25px;
  border: 1px solid var(--border-color);
  background-color: #FFFFFF;
  text-transform: uppercase;
  font-size: 1.3rem;
  font-family: var(--sans-serif-font), sans-serif;

  &:hover, &:active, &:focus {
    border-color: var(--border-color);
    background: #f2f2f2;
  }
}
